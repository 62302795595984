import React from "react";

export const AppFooter = () => {
  return (
    <footer className="bg-slate py-10">
      <div className="container mx-auto text-center text-sand font-light text-sm sm:text-base">
        <p className="mb-4">©2025 by Noah Fernandes</p>
        <a
          className="mb-4 inline-block underline"
          href="mailto:hello@noahfernandes.com"
          target="_blank"
          rel="noreferrer"
        >
          hello@noahfernandes.com
        </a>
        <div className="flex justify-center space-x-4">
          <a
            href="https://www.instagram.com/your.transforming.journey/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sand"
          >
            Instagram
          </a>
          <a
            href="https://www.youtube.com/channel/UCQI1HBwXk0Ywu0wzNLW8kTQ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sand"
          >
            YouTube
          </a>
          <a
            href="https://www.meetup.com/amsterdam-non-violent-communication-practice-group/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sand"
          >
            Meetup
          </a>
        </div>
      </div>
    </footer>
  );
};
