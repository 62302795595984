import React from "react";
import {
  FaEnvelope,
  FaCalendarAlt,
  FaInstagram,
  FaYoutube,
  FaUsers,
} from "react-icons/fa";

export const ContactContent = () => {
  return (
    <div className="text-sand p-10 sm:p-20">
      <div className="sm:flex">
        <div className="sm:w-1/3 sm:pr-8">
          <h2 className="text-4xl font-serif">Contact Information</h2>
          <p className="text-lg mt-4">
            If you’re ready to take the next step on your transformational
            journey of healing and growth and build meaningful, thriving
            relationships, I’d love to hear from you. Let’s connect!
          </p>
        </div>

        <div className="sm:w-2/3 mt-8 sm:mt-0 space-y-10">
          <div className="flex items-start gap-4">
            <div className="w-10 flex-shrink-0 text-3xl text-mud">
              <FaEnvelope />
            </div>
            <div>
              <h3 className="text-xl font-medium">Email Me</h3>
              <p className="mt-1">
                Have questions? Feel free to email me anytime.
              </p>
              <a
                href="mailto:hello@noahfernandes.com"
                target="_blank"
                rel="noreferrer"
                className="text-mud underline text-lg mt-1 block"
              >
                hello@noahfernandes.com
              </a>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <div className="w-10 flex-shrink-0 text-3xl text-mud">
              <FaCalendarAlt />
            </div>
            <div>
              <h3 className="text-xl font-medium">Book a Session</h3>
              <p className="mt-1">
                Ready to start? You can book a session with me directly.
              </p>
              <a
                href="https://calendar.google.com/calendar/u/0/appointments/AcZssZ1uYzSnrAolR0_GKYkkYSHWjrkzEgj6EHcJmXw="
                target="_new"
                className="text-mud underline text-lg mt-1 block"
              >
                Visit my booking page
              </a>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <div className="w-10 flex-shrink-0 text-3xl text-mud">
              <FaInstagram />
            </div>
            <div>
              <h3 className="text-xl font-medium">Follow Me on Instagram</h3>
              <p className="mt-1">Stay connected for updates and insights.</p>
              <a
                href="https://www.instagram.com/your.transforming.journey/"
                target="_new"
                className="text-mud underline text-lg mt-1 block"
              >
                @your.transforming.journey
              </a>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <div className="w-10 flex-shrink-0 text-3xl text-mud">
              <FaYoutube />
            </div>
            <div>
              <h3 className="text-xl font-medium">Watch on YouTube</h3>
              <p className="mt-1">Explore helpful videos and resources.</p>
              <a
                href="https://www.youtube.com/channel/UCQI1HBwXk0Ywu0wzNLW8kTQ"
                target="_new"
                className="text-mud underline text-lg mt-1 block"
              >
                Visit my channel
              </a>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <div className="w-10 flex-shrink-0 text-3xl text-mud">
              <FaUsers />
            </div>
            <div>
              <h3 className="text-xl font-medium">Join My NVC Group</h3>
              <p className="mt-1">
                Participate in my free Non-Violent Communication practice group.
              </p>
              <a
                href="https://www.meetup.com/amsterdam-non-violent-communication-practice-group/"
                target="_new"
                className="text-mud underline text-lg mt-1 block"
              >
                Meetup.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
