import React from "react";
import {
  FaUserEdit,
  FaSpa,
  FaHandHoldingHeart,
  FaClipboardList,
} from "react-icons/fa";

export const WhyWorkWithMeContent = () => {
  return (
    <div>
      <h2 className="text-4xl sm:text-5xl font-serif text-center mb-12 text-mud">
        Why Work With Me
      </h2>

      <div className="grid md:grid-cols-2 gap-6 md:gap-12">
        {/* Personalized Approach */}
        <div className="bg-mud p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-4">
            <FaUserEdit className="text-sand text-xl mr-4" />
            <h3 className="text-xl font-semibold text-sand">
              Personalized Approach
            </h3>
          </div>
          <p className="text-sand">
            I take a deeply personalised approach to coaching and counselling.
            Your experiences, challenges, and goals are unique, and I will
            tailor my approach to suit you. Together, we will create a path that
            works specifically for your situation, ensuring that you receive the
            support you truly need.
          </p>
        </div>

        {/* Holistic Healing */}
        <div className="bg-mud p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-4">
            <FaSpa className="text-sand text-xl mr-4" />
            <h3 className="text-xl font-semibold text-sand">
              Holistic Healing
            </h3>
          </div>
          <p className="text-sand">
            I believe in healing on all levels: emotional, mental, and
            spiritual. By understanding the interconnectedness of your life, we
            can address the root causes of your challenges and foster lasting,
            deep healing. My holistic approach helps you move towards balance
            and wholeness in every aspect of your life.
          </p>
        </div>

        {/* Empathy and Compassion */}
        <div className="bg-mud p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-4">
            <FaHandHoldingHeart className="text-sand text-xl mr-4" />
            <h3 className="text-xl font-semibold text-sand">
              Empathy and Compassion
            </h3>
          </div>
          <p className="text-sand">
            With my own personal experiences and years of therapy, I understand
            the deep pain that can come from relationship wounds. I bring
            empathy and compassion to every session, creating a safe space where
            you feel heard, validated, and supported as you embark on your
            healing journey.
          </p>
        </div>

        {/* Proven Experience */}
        <div className="bg-mud p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-4">
            <FaClipboardList className="text-sand text-xl mr-4" />
            <h3 className="text-xl font-semibold text-sand">
              Proven Experience
            </h3>
          </div>
          <p className="text-sand">
            I have a background in navigating personal challenges, including
            overcoming family wounds, ADHD, and the complexities of cultural
            integration. My own journey has equipped me with the tools, empathy,
            and understanding to guide you through your own challenges, so you
            can heal and create the relationships you desire.
          </p>
        </div>
      </div>
    </div>
  );
};
