import React from "react";

export const AppSection = ({ id, children, className }) => {
  console.log(className);
  const classes = className || "";
  return (
    <section
      id={id}
      className={"min-h-screen px-4 py-10 sm:px-10 sm:py-40 " + classes}
    >
      <div className="container max-w-5xl">{children}</div>
    </section>
  );
};
