import { AppSection } from "./components/common/AppSection.jsx";
import { SectionQuote } from "./components/SectionQuote.jsx";
import { AboutContent } from "./components/AboutContent.jsx";
import { TestimonialsContent } from "./components/TestimonialsContent.jsx";
import { ContactContent } from "./components/ContactContent.jsx";
import { HowICanHelpContent } from "./components/HowICanHelpContent.jsx";
import { WhyWorkWithMeContent } from "./components/WhyWorkWithMeContent.jsx";
import { HomeContent } from "./components/HomeContent.jsx";
import { AppFooter } from "./components/common/AppFooter.jsx";
import "./App.css";

function App() {
  return (
    <div className="App w-screen h-screen bg-sand overflow-auto relative">
      <AppSection className="bg-gradient-to-r from-red-500 to-orange-500 flex items-center">
        <HomeContent />
      </AppSection>

      <SectionQuote
        quote="Out beyond ideas of wrongdoing and rightdoing, there is a field. I’ll meet you there."
        author="Rumi"
      />

      <AppSection>
        <AboutContent />
      </AppSection>

      <AppSection className="bg-mud">
        <HowICanHelpContent />
      </AppSection>

      <AppSection>
        <WhyWorkWithMeContent />
      </AppSection>

      <AppSection className={"bg-mud"}>
        <TestimonialsContent />
      </AppSection>

      <AppSection className="bg-gradient-to-r from-red-500 to-orange-500 flex items-center">
        <ContactContent />
      </AppSection>

      <AppFooter />
    </div>
  );
}

export default App;
