import React from "react";
import { FaHeartbeat, FaPeopleCarry, FaSeedling } from "react-icons/fa";

export const HowICanHelpContent = () => {
  return (
    <div>
      <h2 className="text-4xl sm:text-5xl font-serif text-center mb-12 text-sand">
        How I Can Help
      </h2>

      <div className="grid md:grid-cols-3 gap-6 md:gap-16">
        {/* Heal Relationship Wounds */}
        <div className="bg-sand p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div className="flex flex-col items-center mb-6">
            <FaHeartbeat className="text-mud text-5xl mb-4" />
            <h3 className="text-xl font-semibold text-mud text-center">
              Heal Relationship Wounds
            </h3>
          </div>
          <p className="text-slate">
            I understand how painful it can be to carry emotional scars from
            past relationships. I work with clients who are dealing with the
            aftermath of divorce, heartbreak, or unhealthy relationship
            patterns. Together, we’ll explore the root causes of these wounds
            and help you heal, moving forward with greater emotional resilience
            and understanding.
          </p>
        </div>

        {/* Build Authentic Connections */}
        <div className="bg-sand p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div className="flex flex-col items-center mb-6">
            <FaPeopleCarry className="text-mud text-5xl mb-4" />
            <h3 className="text-xl font-semibold text-mud text-center">
              Build Authentic Connections
            </h3>
          </div>
          <p className="text-slate">
            Authenticity in relationships is key to feeling truly seen and
            connected. I help you discover and embrace who you truly are, so
            that you can attract and nurture relationships that are healthy,
            genuine, and fulfilling. Through my work, you’ll learn how to
            cultivate meaningful connections with yourself and others.
          </p>
        </div>

        {/* Transform Negative Patterns */}
        <div className="bg-sand p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div className="flex flex-col items-center mb-6">
            <FaSeedling className="text-mud text-5xl mb-4" />
            <h3 className="text-xl font-semibold text-mud text-center">
              Transform Negative Patterns
            </h3>
          </div>
          <p className="text-slate">
            Whether it's relationship cycles that keep repeating themselves or
            internal patterns that keep you stuck, I help you break free. I will
            guide you through understanding the underlying causes of these
            patterns and empower you to create healthier, more positive dynamics
            in your relationships.
          </p>
        </div>
      </div>
    </div>
  );
};
