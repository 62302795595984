import React from "react";
import logo from "../logo.png";

export const HomeContent = () => {
  return (
    <div className="text-sand">
      <div className="flex justify-center">
        <img src={logo} alt="Lotus Coconut Logo" className="w-40 sm:w-1/5" />
      </div>
      <div className="text-center mt-10 m-auto text-sand">
        <h1 className="text-4xl sm:text-5xl font-serif">Noah Fernandes</h1>
        <p className="text-xl sm:text-2xl mt-2">
          Trauma-informed Relationship Counsellor
        </p>
        <p className="mt-6 text-lg sm:text-xl sm:w-3/5 m-auto">
          Helping individuals heal and nurture meaningful, compassionate
          connections through trauma-informed guidance
        </p>
        <a
          className="mt-10 inline-block border-sand	border-2 p-2 rounded-lg hover:bg-sand hover:text-mud transition duration-200"
          rel="noreferrer"
          target="_blank"
          href="https:calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0E_32_VXl6zfrk4DtDnE5AASaxJs_PymtFWihUK9-Ksm-n3DGsb39OZWvwD_xcy0TkBVHQYeQb"
        >
          Book a free discovery call
        </a>
      </div>
    </div>
  );
};
