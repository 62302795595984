import React from "react";
import profile from "../profile.jpg";

export const AboutContent = () => {
  return (
    <div className="text-mud px-8 sm:px-20 py-10">
      <div className="sm:flex items-top">
        <div className="sm:w-1/3 sm:pr-8">
          <img
            src={profile}
            alt="Noah Fernandes profile"
            className="rounded-lg shadow-lg w-full"
          />
        </div>
        <div className="sm:w-2/3 mt-8 sm:mt-0">
          <h1 className="text-4xl font-serif font-light text-mud">
            Hi, I'm Noah
          </h1>
          <p className="text-xl mt-4">
            I am a Trauma-Informed Relationship Counsellor based in Amsterdam.
          </p>
          <p className="mt-6 leading-relaxed">
            I empower individuals to heal themselves and their relationships,
            nurturing meaningful connections and creating a more compassionate
            and harmonious world. My approach is holistic, considering the
            bigger picture of your life because every part of it shapes the
            relationships you have.
          </p>
          <p className="mt-6 leading-relaxed">
            Growing up, I faced the complexities of a multicultural upbringing,
            alongside personal challenges like divorce, ADHD, and family
            struggles. I know how it feels to be stuck in unhealthy patterns or
            burdened by emotional pain. Through years of inner work, I’ve
            transformed my own relationships into ones that are healthy,
            meaningful, and deeply fulfilling. Today, I thrive in the
            connections I create, and I know the freedom and joy that come with
            truly authentic relationships.
          </p>
          <p className="mt-4 leading-relaxed">
            If you are experiencing hardships in your relationships or carrying
            wounds from the past, please reach out. I would be honoured to guide
            you on your journey to healing, self-discovery, and the
            relationships you’ve always longed for.
          </p>
          <div className="mt-8">
            <a
              className="inline-block border-mud text-mud border-2 p-2 rounded-lg hover:bg-mud hover:text-sand transition duration-200"
              rel="noreferrer"
              target="_blank"
              href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0E_32_VXl6zfrk4DtDnE5AASaxJs_PymtFWihUK9-Ksm-n3DGsb39OZWvwD_xcy0TkBVHQYeQb"
            >
              Book a free discovery call
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
